<template>
  <div id="timeout-error">
    <div class="error-content">
      <div class="mb-3">
        <img
          src="@/assets/svg/warning-sign.svg"
          class="m-auto"
          alt="Warning sign"
        />
      </div>
      <h1 class="text-xl font-black">Mohon maaf,</h1>
      <div class="text-gray-600">
        Server sedang mengalami gangguan teknis dan sedang dalam perbaikan,<br />Silahkan
        akses beberapa saat menit lagi. Jika muncul halaman ini lagi, silahkan
        hubungi admin.
      </div>
      <div class="mt-4 flex justify-center">
        <a
          href="https://wa.me/6285155421080"
          target="_blank"
          rel="noopenner"
          class="error-button"
        >
          <img
            src="@/assets/svg/telegram.svg"
            class="align-top mr-2"
            alt="Telegram"
          />
          Hubungi Admin
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
#timeout-error {
  @apply flex;
  @apply items-center;
  @apply justify-center;

  background: #18446b;
  min-height: 100vh;
}

.error-content {
  @apply rounded-md;
  @apply px-8;
  @apply py-10;
  @apply text-center;
  @apply mx-3;

  background: #f1f1f1;
}

.error-content-text {
  color: #909090;
}

.error-button {
  @apply py-3;
  @apply px-6;
  @apply font-semibold;
  @apply text-white;
  @apply rounded-md;
  @apply flex;
  @apply items-center;

  background: #18446b;
}

.error-button:hover {
  background: #0e2840;
}
</style>
